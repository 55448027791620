.im__section__one {
  padding: 0 20%;
  background-color: #f4eaef;
  background: #e2e8f1;
  min-height: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.im__section__one__start {
  width: 40%;
  padding: 5% 0;
}
.im__section__one__start > h1 {
  font-size: 45px;
  font-weight: 700;
  color: #000066;
  margin-bottom: 20px;
}
.im__section__one__start > p {
  font-size: 14px;
  line-height: 25.5px;
  width: 85%;
  font-weight: 400;
  color: #999;
  margin-bottom: 20px;
}
.im__section__one__start > span {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.im__section__one__start > span > button {
  padding: 10px 15px;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  background: white;
  color: #000066;
  cursor: pointer;
}
.im__section__one__start > span > button:last-of-type {
  color: white;
  background: #000066;
}
.im__section__one__end {
  width: 50%;
  display: flex;
  justify-content: end;
}
.im__section__one__end > video {
  height: 400px;
  width: 640px;
}
.custom-shape-divider-top-1722811141 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1722811141 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
  background: #000066;
}

.custom-shape-divider-top-1722811141 .shape-fill {
  fill: #fff;
}
.im__section__beans {
  padding: 0 20%;
  background-color: #000066;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.im__section__beans__start {
  width: 50%;
}
.im__section__beans__end {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: end;
}
.im__section__beans__end > img {
  object-fit: cover;
  height: 300px;
  width: 400px;
}
.im__section__tomatoes {
  padding: 5% 20%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.im__section__tomatoes > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000066;
}
.im__section__tomatoes > p {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  width: 600px;
  text-align: center;
  color: #aaa;
}
.im__section__stages {
  padding: 5% 0;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.im__section__stages > div {
  border-radius: 10px;
  padding: 10px;
  /* background-color: #f4eaef; */
  background: #e2e8f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 150px;
  min-width: 250px;
}
.im__section__stages > div > h1 {
  font-size: 15px;
  font-weight: 500;
  color: #000066;
  margin-bottom: 10px;
}
.im__section__stages > div > ul {
  list-style-type: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  color: 777;
}
@media all and (max-width: 768px) {
  .im__section__beans {
    flex-direction: column;
    padding: 0;
  }
  .im__section__beans__start {
    padding: 5% 5%;
    padding-bottom: 0;
    width: 100%;
  }
  .im__section__beans__end {
    width: 100%;
  }
  .im__section__beans__end > img {
    width: 100%;
  }
  .im__section__one {
    display: flex;
    flex-direction: column;
    padding: 5% 5%;
    height: auto;
  }
  .im__section__one__start {
    width: 100%;
  }
  .im__section__one__end {
    width: 100%;
    justify-content: start;
  }
  .im__section__one__start > p {
    width: 100%;
  }
  .im__section__one__end > video {
    height: 100%;
    width: 100%;
  }
  .im__section__tomatoes {
    padding: 5% 5%;
  }
  .im__section__tomatoes > p {
    width: 100%;
  }
  .im__section__stages > div {
    width: 100%;
  }
}
