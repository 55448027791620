.services__section__one {
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/portrait-nurse-sitting-desk-with-laptop-documents-while-wearing-uniform-gloves-face-mask-medical-facility-assistant-looking-camera-with-bottle-pills-table.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.services__section__one > h1 {
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.services__section__two {
  padding: 2.5% 20%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.services__section__two__start {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.services__section__two__start > h2 {
  color: #000066;
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
  margin-bottom: 10px;
}
.services__section__two__start > p {
  color: #444;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.services__section__two__start > h3 {
  color: #000066;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.services__section__two__end {
  width: 45%;
  display: flex;
  align-items: center;
}
.services__section__two__end > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}
.services__section__three {
  padding: 2.5% 20%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 20px;
  background: #e2e8f1;
}

.services__section__four {
  padding: 2.5% 20%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.services__section__five {
  padding: 2.5% 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #000066;
  color: white;
}
.services__section__five > h3 {
  font-size: 16px;
  font-weight: 500;
  color: #eee;
  margin-bottom: 10px;
  text-align: center;
}
.services__section__five__wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  gap: 10px;
}
.services__section__five__wrap > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background-color: white;
  color: #000066;
  border-radius: 5px;
  padding: 20px 20px;
}
.services__section__five__wrap > div > h2 {
  width: 100%;
  color: #000066;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.services__section__five__wrap > div > p {
  color: #000066;
  font-size: 14px;
  line-height: 25px;
  font-weight: 200;
}

@media all and (max-width: 768px) {
  .services__section__one {
    min-height: 70svh;
    background-position: center;
    padding: 5% 5%;
  }
  .services__section__one > h1 {
    font-size: 30px;
  }
  .services__section__two {
    padding: 5% 5%;
    flex-direction: column;
  }
  .services__section__two__start {
    width: 100%;
  }
  .services__section__two__end {
    width: 100%;
  }
  .services__section__three {
    padding: 5% 5%;
    flex-direction: column;
  }
  .services__section__four {
    padding: 5% 5%;
    flex-direction: column;
  }
  .services__section__five {
    padding: 5% 5%;
  }
  .services__section__five__wrap {
    flex-direction: column;
    justify-content: start;
  }
  .services__section__five__wrap > div {
    width: 100%;
  }
}
