.footer {
  background-color: white;
  color: #000066;
  padding: 2.5% 20%;
}
.footer__header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0eef0;
}
.footer__header > span {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer__header > span > h1 {
  font-size: 14px;
  font-weight: 500;
  color: #000066;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer__header > span > p {
  font-size: 14px;
  font-weight: 500;
  color: #000066;
}
.footer__body {
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid #e0eef0;
  padding: 40px 0;
}
.footer__body__nav {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 20px;
}
.footer__body__nav > li {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.footer__body__socials {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer__foot {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 0;
}
.footer__foot > p {
  font-size: 11px;
  line-height: 16px;
  width: 65%;
}
.footer__foot > span {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 30%;
  font-size: 11px;
}

@media all and (max-width: 768px) {
  .footer {
    padding: 10% 5%;
  }
  .footer__foot {
    flex-direction: column;
  }
  .footer__foot > p {
    width: 100%;
  }
  .footer__foot > span {
    width: 100%;
    justify-content: start;
    margin-top: 20px;
  }
}
