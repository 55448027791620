.modal__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
}
.modal__form > img {
  object-fit: contain;
  height: 50px;
}
.modal__form > h1 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.modal__form__container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.modal__form__container > label {
  font-size: 14px;
  font-weight: 500;
  color: #777;
}
.modal__form__container > input {
  font-size: 14px;
  font-weight: 500;
  color: #777;
  border: 1px solid #000066;
  padding: 10px 10px;
  width: 100%;
  border-radius: 5px;
}
.modal__form__container > input:focus {
  outline: none;
}
.modal__form__container > select {
  font-size: 14px;
  font-weight: 500;
  color: #777;
  border: 1px solid #000066;
  padding: 10px 10px;
  width: 100%;
  border-radius: 5px;
}
.modal__form__container > select > option {
  font-size: 14px;
  font-weight: 500;
  color: #777;
  padding: 10px 10px;
  width: 100%;
}
.modal__form__container > span {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.modal__form__container > span > button {
  font-size: 14px;
  font-weight: 500;
  color: #000066;
  border: 1px solid #000066;
  padding: 10px 10px;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  min-width: 100px;
}
.modal__form__container > span > button:last-of-type {
  color: white;
  background: #000066;
  border: 0;
}
.modal__form__message {
  padding: 20px;
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  background-color: lightgreen;
  width: 100%;
  text-align: center;
}
