.cm__section__one {
  padding: 0 20%;
  /* background-color: rgb(223, 201, 223); */
  /* background-image: linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%); */
  background: #000066;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.cm__section__one__start {
  padding: 5% 0;
  width: 45%;
}
.cm__section__one__start > h1 {
  font-size: 45px;
  font-weight: 700;
  color: white;
  margin-bottom: 30px;
}
.cm__section__one__start > p {
  font-size: 14px;
  line-height: 25px;
  width: 85%;
  font-weight: 400;
  color: white;
  margin-bottom: 30px;
}
.cm__section__one__start > span {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.cm__section__one__start > span > button {
  padding: 10px 15px;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  background: white;
  color: #000066;
}
.cm__section__one__start > span > button:last-of-type {
  color: #000066;
  /* background: #583455; */
  background: #e2e8f1;
}
.cm__section__one__end {
  width: 50%;
  display: flex;
  justify-content: end;
}
.cm__section__one__end > img {
  object-fit: contain;
  height: 400px;
}

.cm__section__two {
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f0fa;
}
.cm__section__two > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000066;
}
.cm__section__two__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20%;
}
.cm__section__two__start {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  text-align: end;
}
.cm__section__two__start > h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: 35px;
  margin-bottom: 10px;
  color: #000066;
}
.cm__section__two__start > p {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 30px;
  width: 350px;
}
.cm__section__two__end {
  width: 50%;
  display: flex;
  justify-content: start;
}
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;
  width: 320px;
  border: 4px solid black;
  border-radius: 20px;
  background-color: #f0f0f0;
  box-shadow: 5px 5px 2.5px 6px rgba(209, 218, 218, 1);
}

.box__top-border {
  position: absolute;
  top: 0;
  left: 72px;
  border: 1px solid black;
  background-color: black;
  width: 160px; /* 20px multiplied by 4 for width in Tailwind */
  height: 16px; /* 2px multiplied by 4 for height in Tailwind */
  border-radius: 0 0 20px 20px; /* rounded-br-xl rounded-bl-xl */
}

.box__right-border {
  position: absolute;
  right: -2px;
  border: 4px solid black;
  border-radius: 10px; /* rounded-md */
}

.box__right-border--top {
  top: 112px; /* 14px multiplied by 4 for positioning in Tailwind */
  height: 56px; /* 7px multiplied by 4 for height in Tailwind */
}

.box__right-border--bottom {
  bottom: 288px; /* 36px multiplied by 4 for positioning in Tailwind */
  height: 80px; /* 10px multiplied by 4 for height in Tailwind */
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}

@media all and (max-width: 768px) {
  .cm__section__one {
    padding: 5% 5%;
    flex-direction: column;
  }
  .cm__section__one__start {
    padding: 0;
    width: 100%;
  }
  .cm__section__one__end {
    width: 100%;
    justify-content: start;
  }
  .cm__section__one__start > p {
    width: 100%;
  }
  .cm__section__one__end > img {
    object-fit: contain;
    height: auto;
    width: 100%;
  }
  .cm__section__two__wrap {
    flex-direction: column;
    padding: 0 5%;
  }
  .cm__section__two__start {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .cm__section__two__start > p {
    width: 100%;
  }
  .cm__section__two__end {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
