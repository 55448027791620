.modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(38, 38, 38, 0.7);
}
.modal__content {
  width: 22.5%;
  padding: 1% 1%;
  border-radius: 5px;
  background: white;
  color: #000;
}
.modal__close {
  width: 100%;
  display: flex;
}
.modal__close__icon {
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-text-color);
  color: crimson;
}

@media all and (max-width: 768px) {
  .modal {
    background-color: rgba(38, 38, 38, 0.8);
    position: fixed;
  }
  .modal__content {
    width: 95%;
    height: 100%;
    padding: 5% 0.5%;
  }
}
